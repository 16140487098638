<template>
  <loading v-if="!invoice" />
  <div v-else class="columns is-multiline is-marginless has-padding-50">
    <div class="column is-12">
      <collapse title="Staff controls">
        <site-controls v-if="site" :site-id="siteId" />
        <user-controls :user-id="invoice.userId" />
      </collapse>
    </div>
    <div v-if="site" class="column is-12">
      <collapse title="Site details">
        <site-details :site="site" />
      </collapse>
    </div>
    <div class="column is-12">
      <user-details :user-id="invoice.userId" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminInvoiceAside",
  components: {
    "site-controls": () => import("@shared/admin/_siteControls"),
    "user-controls": () => import("@shared/admin/_userControls"),
    "site-details": () => import("@shared/sites/_siteDetails"),
    "user-details": () => import("@shared/sites/_userDetails")
  },
  data() {
    return {
      invoiceId: this.$route.params.invoiceId
    };
  },
  computed: {
    invoice() {
      return this.$store.getters["invoices/invoice"](this.invoiceId);
    },
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    siteId() {
      if (!this.invoice) return null;
      const item = this.$_.find(
        this.invoice.items,
        item => !!this.$_.get(item, "metadata.siteRef", null)
      );
      return item ? item.metadata.siteRef.id : null;
    }
  },
  watch: {
    siteId: {
      handler(newVal) {
        if (!newVal) return null;
        this.$store.dispatch("sites/observeSite", { siteId: this.siteId });
      },
      immediate: true
    }
  },
  created() {
    this.$store.dispatch("invoices/observeInvoice", {
      invoiceId: this.invoiceId
    });
  },
  beforeDestroy() {
    this.$store.dispatch("sites/unobserveSite", { siteId: this.siteId });
    this.$store.dispatch("invoices/unobserveInvoice", {
      invoiceId: this.invoiceId
    });
  }
};
</script>
